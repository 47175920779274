import React from 'react';
import ReactDOM from 'react-dom';


import { Scroller, Splitter, SplitterSide, SplitterContent, Card, Page, Toolbar, Button, Fab, Icon, BackButton, Row, Col, LazyList, List, ListTitle, ListItem } from 'react-onsenui';
import { Input } from 'react-onsenui';
import {WaterfallGrid} from 'react-waterfall-grid';
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'

import Gun from 'gun/gun';
import SEA from 'gun/sea';

require('gun/lib/then.js');

class PublicProfile extends React.Component {

    constructor(props) {
        super(props);

    }


    render() {
        return(
            <div>
                Public Profile

            </div>
        );
    }

}

export default PublicProfile;