import React from 'react';
import ReactDOM from 'react-dom';


import { Toast, Card, Page, Toolbar, Button, Fab, Icon, BackButton, Row, Col, LazyList, List, ListItem } from 'react-onsenui';
import { Input } from 'react-onsenui';
import {WaterfallGrid} from 'react-waterfall-grid';
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'

import Gun from 'gun/gun';
import SEA from 'gun/sea';


import Post from './Post';
import _default from '@uiw/react-markdown-preview';

require('gun/lib/then.js');

class UserProfile extends React.Component {
    constructor(props) {
        super(props);

        this.renderToolbar = this.renderToolbar.bind(this);
        this.onAddFriend = this.onAddFriend.bind(this);
        this.checkIfFriend = this.checkIfFriend.bind(this);
        this.onRemoveFriend = this.onRemoveFriend.bind(this);

        this.user = props.user;
        this.gun = props.gun;
        this.navigator = props.navigator;

        this.state = {
            user_ref : props.user_ref,         
            user : props.post_username,
            user_pubKey : props.post_pubKey,
            is_friend : false,

            isFriendAddToastOpen : false,
            friendToastError : false,

            post_feed : [],

        }

    }

    componentDidMount() {
        this.checkIfFriend();

        this.gun.get(this.state.user_ref.public_posts).map().once( async data => {
            console.log("FOUND A POST");
            console.log(data);
            console.log(data.message);
            console.log(this.gun.user(data).get('pub'));
            this.gun.user(data).get('pub').once( async (pub) => {
                console.log(pub);
                let message = await SEA.verify(data.message, pub);
                let epub = await this.gun.user(this.state.user_ref).get('epub');
                let ts = Gun.state.is(data, 'message');

                let post = {
                    post_username : this.state.user,
                    user_ref : this.state.user_ref,
                    message : message,
                    time_stamp : ts,
                    post_pubKey : epub,
                }

                console.log(epub);
                console.log(message);

                this.setState({ post_feed : [...this.state.post_feed, post] });
            
                console.log("POST INFO");
                console.log(post);
            });

        })


    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log("Post component update");
        if (this.props != prevProps) {
            this.checkIfFriend();
        }
    }

    checkIfFriend() {
        console.log("Checking if this user is a friend");
        for (let friend of this.props.profile.friends_list) {
            console.log("friend found");
            console.log(friend);
            if (friend.pubKey == this.state.user_pubKey) {
                this.setState({ is_friend : true });
            }
        }
    }

    async onAddFriend(e) {
        if (this.user.is) {
            let friends_list_node = this.user.get('friends_list').set( { user_ref : this.state.user_ref, pubKey : this.state.user_pubKey, username : this.state.user }, (ack) => {
                console.log("Friend added returned");
                console.log(ack);
                if (ack.err) {
                    this.setState({ friendToastError : true });
                    this.setState({ isFriendAddToastOpen : true });
                    setTimeout(() => { //This is the timeout for the Toast notification 
                        this.setState({ isFriendAddToastOpen: false });
                    }, 3000)  
                } else {
                    this.setState({ friendToastError : false });
                    this.setState({ isFriendAddToastOpen : true });
                    setTimeout(() => { //This is the timeout for the Toast notification 
                        this.setState({ isFriendAddToastOpen: false });
                    }, 3000)  
                }
            });

        }
    }

    async onRemoveFriend(e) {
        if (this.user.is) {
            
        }
    }


    renderToolbar() {
        return (
          <Toolbar>
              <div className='left'>
                  <BackButton></BackButton>
              </div>
              <div className="center">User Profile</div>
          </Toolbar>
        );
      }
  
    
      render() {
        return (
          <Page renderToolbar={this.renderToolbar} style={{textAlign: 'center'}} >
            <div className="center" style={{textAlign: 'center', width : 'fit-content', display: 'inline-block'}}>
            <Row>
                
                <img
                    src={'https://avatars.dicebear.com/api/initials/' + this.state.user + '.svg'}
                    alt="avatar"
                    className="center"
                    style={{
                        width : 40,
                        height : 40,
                        margin : 2,
                        borderRadius : '50%',
                    }}/>
                <h1>{this.state.user}</h1>
                {!this.state.is_friend && (
                    <div><Button onClick={this.onAddFriend}><Icon icon="plus" /></Button></div>
                )}
                {this.state.is_friend && (
                <div><Button onClick={this.onRemoveFriend}><Icon icon="fa-user-slash"/></Button></div>
                )}
            </Row>
            <Row>
                <List>
                    <ListItem>Public Key: {this.state.user_pubKey}
                        <Button onClick={() => {
                            navigator.clipboard.writeText(this.state.user_pubKey)}}>
                            <Icon icon="fa-copy" style={{ width : 16, height : 16}} />
                        </Button>
                    </ListItem>
                    <ListItem></ListItem>
                </List>
            </Row>
            </div>
            <WaterfallGrid children={this.state.post_feed.map( (post, i ) => {
                    return(
                        <div key={i} style={{ width : 320 }}>
                            <Post
                                post={post}
                                gun={this.gun}
                                user={this.user}
                                profile={this.props.profile}
                                navigator={this.props.navigator}
                                />
                        </div>)
                })} 
                childWidth={320}
                styleGridContainer={{ width: "100%", position: "relative", justifyContent: "center", zIndex: 1 }}/>   
            {this.state.friendToastError && (
                <Toast isOpen={this.state.isFriendAddToastOpen}>
                    Error.
                </Toast>        
            )}
            {!this.state.friendToastError && (
                <Toast style={{
                    width : 100,
                    marginLeft : 'auto',
                    marginRight : 'auto'
                    }} isOpen={this.state.isFriendAddToastOpen}>
                Friend Added!
                </Toast>        
            )}
          </Page>
        );
      }
}

export default UserProfile;