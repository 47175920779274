import React from 'react';
import ReactDOM from 'react-dom';


import { BottomToolbar, Scroller, Splitter, SplitterSide, SplitterContent, Card, Page, Toolbar, Button, Fab, Icon, BackButton, Row, Col, LazyList, List, ListTitle, ListItem } from 'react-onsenui';
import { Input } from 'react-onsenui';
import {WaterfallGrid} from 'react-waterfall-grid';
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import MDEditor from '@uiw/react-md-editor';

import Gun from 'gun/gun';
import SEA from 'gun/sea';

import LogoutButton from './LogoutButton';
import NewChannel from './NewChannel';
import cons from 'gun';

require('gun/lib/then.js');

class MessageForm extends React.Component {
    constructor(props) {
        super(props);

        this.onSend = this.onSend.bind(this);

        this.gun = props.gun;
        this.user = props.user;

        this.state ={
            text : "",
        }

    }

    async onSend(e) {
        e.preventDefault();
        //console.log(e);
        if (this.user.is) {
            console.log("User is signed in");
            this.gun.get(this.props.friend_ref).once( async (data) => { //THIS ONE WORKS
                console.log(data);
                let signed_message = await SEA.sign(this.state.text, this.user._.sea);
                console.log(signed_message);
                this.setState({ text : "" });
                this.user.get('friend_chats').get(data.pub).set( { message : signed_message, time_stamp : new Date().toISOString() }, async (ack) => {
                    console.log(ack);
                    this.props.updateHandler();
                });

            });
     
        }

    }   

    render() {
        return(
            <div style={{
                textAlign: 'center',
                position: 'absolute',
                bottom: 4,
                left : 'auto',
                width : '100%'
                }}>
                <div style={{ display: 'inline-block', width : '100%' }}>
                <form className="message_form" onSubmit={this.onSend}>
                    <Input
                        value={this.state.text} float
                        onChange={(event) => { this.setState({text: event.target.value})} }
                        modifier='material'
                        placeholder='Chat Message'/>
                    {" "}<Button type="submit" onClick={this.onSend}>Say</Button>
                </form>
                </div>
            </div>
        );
    }
}

class Message extends React.Component {
    constructor(props) {
        super(props);
        
        this.user = props.user;
        this.gun = props.gun;

        console.log(props.message);

    }


    render() {
        return(
            <Row>
                <Col width="50">
                <img
                    src={'https://avatars.dicebear.com/api/initials/' + this.props.message.sender + '.svg'}
                    alt="avatar"
                    style={{
                        width : 40,
                        height : 40,
                        margin : 2,
                        borderRadius : '50%',
                    }}/>
                </Col>
                <Col>
                    <div style={{ 
                       position: 'absolute',
                       left: 60,
                       top: 20      ,
                       width: 'auto',
                       height: 'auto',
                       fontSize : 16}}>
                        {this.props.message.message}
                    </div>
                   <div style={{ 
                       position: 'absolute',
                       right: 0,
                       bottom: 0,
                       width: 'auto',
                       height: 'auto',
                       fontSize : 10}}>
                        <time>{new Date(this.props.message.time_stamp).toLocaleString()}</time>
                    </div>
                </Col>
            </Row>
        )
    }

}

class ChatBox extends React.Component {
    constructor(props) {
        super(props);

        this.setupChat = this.setupChat.bind(this);
        this.updateHandler = this.updateHandler.bind(this);

        this.user = props.user;
        this.gun = props.gun;

        this.state = {
            friend_name : null,
            messages : [],
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps != this.props) {
            console.log("Did update with different props");
            if (this.props.friend_ref != prevProps.friend_ref) {
                this.setState({ messages : [] });
            }
            this.setupChat();
        }
    }

    componentDidMount() {
        this.setupChat();
    }

    updateHandler() {
        this.setupChat();
    }

    scrollToBottom = () => {
        //this.messagesEnd.scrollIntoView({ behavior: "smooth" });
        let chat_feed = document.getElementById('chat-feed-page');
        chat_feed.scrollTop = chat_feed.scrollHeight;
    }

    setupChat() {
        //console.log("Setting up Chat");
        let myPub = this.user.is.pub
        //console.log("My public key:" + myPub);
        //Get the other persons messages
        //console.log("Getting my their messages to me");

        this.gun.get(this.props.friend_ref).get('friend_chats').get(myPub).map().once( async (data, key) => {
            console.log("Message in their user space for me")
            console.log(data);
            console.log(key);

            let friend_name = await this.gun.user(this.props.friend_key).get('alias');
            let message = {
                message_key : key,
                message : await SEA.verify(data.message, await this.gun.user(data).get('pub')),
                user_ref : await this.gun.user(data),
                sender : await this.gun.user(data).get('alias'),
                time_stamp : Gun.state.is(data, 'message'),
                post_pubKey : await this.gun.user(data).get('epub'),
            }
            //console.log(message);


            if (!(this.state.messages.filter( e => e.message_key === key).length > 0)) {
                this.setState({ messages : [...this.state.messages, message].sort( (a, b) => a.time_stamp - b.time_stamp) });
            }

            let friend_pub_key = await this.gun.user(data).get('pub')
            
            //Get my messages
            //console.log("Getting my messages to them");
            this.user.get('friend_chats').get(friend_pub_key).map().once( async (data, key) => {
                console.log("Message in my user space for them")
                console.log(data);
                console.log(key);

                let my_name = await this.user.get('alias');
                let message = {
                    message_key : key,
                    message : await SEA.verify(data.message, await this.gun.user(data).get('pub')),
                    user_ref : await this.gun.user(data),
                    sender : await this.gun.user(data).get('alias'),
                    time_stamp : Gun.state.is(data, 'message'),
                    post_pubKey : await this.gun.user(data).get('epub'),
                }

                //console.log(message);

                if (!(this.state.messages.filter( e => e.message_key === key).length > 0)) {
                    this.setState({ messages : [...this.state.messages, message].sort( (a, b) => a.time_stamp - b.time_stamp) });
                }
    
                this.scrollToBottom();
            });

        });

       
    }

 

    render() {
        return(
            <Page id="chat-feed-page" style={{textAlign: 'center'}}  renderBottomToolbar={ err => {
                return(
                    <BottomToolbar modifier="material" style={{ height : 60}}><MessageForm updateHandler={this.updateHandler} gun={this.gun} user={this.user} friend_ref={this.props.friend_ref} /></BottomToolbar>
                );

            }}>
                <List style={{ display: 'inline-block' }} >
                    {this.state.messages.map( (message, i ) => {
                    //console.log(message);
                    return(
                        <ListItem key={i} style={{ width : 600 }}>
                            <Message
                                message={message}
                                gun={this.gun}
                                user={this.user}
                                profile={this.props.profile}
                                navigator={this.props.navigator}
                                />
                        </ListItem>)
                    })}
                </List>
                <br />
                <br />
                <div id={"end-message"} style={{ float:"left", clear: "both" }}></div>
            </Page>
        );
    }

}

export default ChatBox;