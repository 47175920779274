import React from 'react';

import { Card, Page, Toolbar, Button, Fab, Icon, BackButton } from 'react-onsenui';
import { Input } from 'react-onsenui';
import MDEditor from '@uiw/react-md-editor';

import Gun from 'gun/gun';
import SEA from 'gun/sea';

import LogoutButton from './LogoutButton';

require('gun/lib/then.js');

class NewChannel extends React.Component {
    constructor(props) {
        super(props);
        console.log(props);
        this.updateChannelName = this.updateChannelName.bind(this);
        this.onCreate = this.onCreate.bind(this);

        this.user = props.user;
        this.gun = props.gun;
        this.navigator = props.navigator;

        this.state = {
          channel_name : "",
        }
    }
    
    renderToolbar() {
      return (
        <Toolbar>
            <div className='left'>
                <BackButton></BackButton>
            </div>
            <div className="center">New Channel</div>
        </Toolbar>
      );
    }

    updateChannelName(e) {
      console.log(e);
        this.setState({ channel_name : e });
    }

    async onCreate(e) {
      if (this.user.is) {
        console.log("Creating message.");
      }

    }   
  
    render() {
      return (
        <Page renderToolbar={this.renderToolbar}>
          <div style={{textAlign: 'center'}}>
          <br />
          <Input modifier="underbar" placeholder="Channel Name" float onInput={this.updateChannelName} />
            <Button onClick={this.onCreate}>Create</Button>
          </div>
        </Page>
      );
    }
  }

export default NewChannel