import React from 'react';
import ReactDOM from 'react-dom';


import { Card, Page, Toolbar, Button, Fab, Icon, BackButton, Row, Col, LazyList, List, ListItem } from 'react-onsenui';
import { Input } from 'react-onsenui';
import {WaterfallGrid} from 'react-waterfall-grid';
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'

import Gun from 'gun/gun';
import SEA from 'gun/sea';

import UserProfile from './UserProfile';

require('gun/lib/then.js');

class Post extends React.Component {
    constructor(props) {
        super(props);

        this.openUserProfile = this.openUserProfile.bind(this);
        this.checkIfFriend = this.checkIfFriend.bind(this);

        this.user = props.user;
        this.gun = props.gun;
        this.navigator = props.navigator;

        this.state = {
            is_friend : false,
        }

    }

    componentDidMount() {
        this.checkIfFriend();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log("Post component update");
        if (this.props != prevProps) {
            this.checkIfFriend();
        }
    }

    checkIfFriend() {
        for (let friend of this.props.profile.friends_list) {
            if (friend.pubKey == this.props.post.post_pubKey) {
                this.setState({ is_friend : true });
            }
        }
    }

    openUserProfile(e) {
        this.props.navigator.pushPage({comp: UserProfile, props: {
            key: 'userprofile',
            gun : this.gun,
            user : this.user,
            profile : this.props.profile,
            navigator : this.props.navigator,
            
            user_ref : this.props.post.user_ref,
            post_username : this.props.post.post_username,
            post_pubKey : this.props.post.post_pubKey,
        }});
    }

    render() {
        return(
                <Card>
                    <List>
                        <ListItem key="post_header" onClick={this.openUserProfile} tappable>
                            <Col width="50">
                                <img
                                    src={'https://avatars.dicebear.com/api/initials/' + this.props.post.post_username + '.svg'}
                                    alt="avatar"
                                    style={{
                                        width : 40,
                                        height : 40,
                                        margin : 2,
                                        borderRadius : '50%',
                                    }}/>
                            </Col>
                            <Col>
                                {this.props.post.post_username + " "}
                                {this.state.is_friend && (
                                    <Icon icon="fa-user-friends" />
                                )}
                            </Col>
                            </ListItem>
                        <ListItem key="post_content" tappable><ReactMarkdown children={this.props.post.message} remarkPlugins={[gfm]} /></ListItem>
                        <ListItem key="post_timestamp" tappable><small><time>{new Date(this.props.post.time_stamp).toLocaleString()}</time></small></ListItem>
                    </List>
                </Card> 
        );
    }
}

export default Post;