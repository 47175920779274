import React from 'react';
import ReactDOM from 'react-dom';


import { Splitter, SplitterSide, SplitterContent, Card, Page, Toolbar, Button, Fab, Icon, BackButton, Row, Col, LazyList, List, ListItem } from 'react-onsenui';
import { Input } from 'react-onsenui';
import {WaterfallGrid} from 'react-waterfall-grid';
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'

import Gun from 'gun/gun';
import SEA from 'gun/sea';

import LogoutButton from './LogoutButton';
import FriendsList from './FriendsList';
import PublicProfile from './PublicProfile';
import UserGraph from './UserGraph';

require('gun/lib/then.js');

class Profile extends React.Component {
    constructor(props) {
        super(props);

        this.onClose = this.onClose.bind(this);
        this.onOpen = this.onOpen.bind(this);

        this.closeAllPages = this.closeAllPages.bind(this);
        this.showFriendsList = this.showFriendsList.bind(this);
        this.showPublicProfile = this.showPublicProfile.bind(this);
        this.showUserGraph = this.showUserGraph.bind(this);

        this.user = props.user;
        this.gun = props.gun;

        this.state = {
            isOpen : false, //Side bar open or closed

            show_public_profile : true,
            show_friends_list : false,
            show_user_graph : false,
        }

    }


    componentDidMount() {

    }

    onOpen(e) {this.setState({isOpen: true});}
    onClose(e) {this.setState({isOpen: false});}

    closeAllPages() {
        this.setState({ show_public_profile : false });
        this.setState({ show_friends_list : false });
        this.setState({ show_user_graph : false });
    }
    showPublicProfile() {
        this.closeAllPages();
        this.setState({ show_public_profile : true });
    }
    showFriendsList() {
        this.closeAllPages();
        this.setState({ show_friends_list : true });
    }
    showUserGraph() {
        this.closeAllPages();
        this.setState({ show_user_graph : true });
    }

    render() {
        return(
            <Page renderToolbar={ err => {
                return(
                    <Toolbar>
                        <div className="left">
                            {!this.state.isOpen && (
                                <Button onClick={this.onOpen}><Icon icon="fa-angle-double-right"/></Button>
                            )}
                            {this.state.isOpen && (
                                <Button onClick={this.onClose}><Icon icon="fa-angle-double-left"/></Button>
                            )}
                        </div>
                        <div className="center">Profile</div>
                        {this.props.profile && (
                            <div className="right"><LogoutButton gun={this.gun} user={this.user} profile={this.state.profile} logoutHandler={this.props.logoutHandler} /></div>
                        )}
                    </Toolbar>
                )}} > 

                    <Splitter>
                    <SplitterSide
                        side="left"
                        width={200}
                        swipeable={true}
                        collapse={true}
                        isOpen={this.state.isOpen}
                        onClose={this.onClose}
                        onOpen={this.onOpen}>
                    <Page renderToolbar={ err => {return(<Toolbar><div className="center">{this.props.profile.username}</div></Toolbar>)}}>
                        <List>
                            <ListItem onClick={this.showPublicProfile} tappable><Icon icon="fa-user-circle" />{"  "}Profile</ListItem>
                            <ListItem onClick={this.showFriendsList} tappable><Icon icon="fa-user-friends" />{"  "}Friends</ListItem>
                            <ListItem onClick={this.showUserGraph} tappable><Icon icon="fa-project-diagram" />{"  "}User Graph</ListItem>
                            </List>
                    </Page>
                    </SplitterSide>
                    <SplitterContent>
                    <Page> 
                    {this.state.show_public_profile && (
                        <PublicProfile gun={this.gun} user={this.user} profile={this.props.profile} />
                    )}
                    {this.state.show_friends_list && (
                        <FriendsList gun={this.gun} user={this.user} profile={this.props.profile} />
                    )}
                    {this.state.show_user_graph && (
                        <UserGraph gun={this.gun} user={this.user} profile={this.props.profile} />
                    )}
                    
                    </Page>
                    </SplitterContent>
                </Splitter>
                            
            </Page>
        );
    }

}

export default Profile;