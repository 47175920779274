import React from 'react';
import ReactDOM from 'react-dom';


import { Card, Page, Toolbar, Button, Fab, Icon, BackButton, Row, Col, LazyList, List, ListItem } from 'react-onsenui';
import { Input } from 'react-onsenui';
import {WaterfallGrid} from 'react-waterfall-grid';
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'

import Gun from 'gun/gun';
import SEA from 'gun/sea';

import LogoutButton from './LogoutButton';
import NewPost from './NewPost';
import UserProfile from './UserProfile';
import Post from './Post';

require('gun/lib/then.js');

class Home extends React.Component {
    constructor(props) {
        super(props);

        this.onNewClick = this.onNewClick.bind(this);
        this.getAutoResponsiveProps = this.getAutoResponsiveProps.bind(this);

        this.user = props.user;
        this.gun = props.gun;

        this.state = {
            text : "",
            post_feed : [],
            profile : props.profile,
        }

    }

    componentDidMount() {
        console.log("ChatBox component mounted.");
        var match = {
            // lexical queries are kind of like a limited RegEx or Glob.
            '.': {
              // property selector
              '>': new Date(+new Date() - 1 * 1000 * 60 * 60 * 3).toISOString(), // find any indexed property larger ~3 hours ago
            },
            '-': 1, // filter in reverse
          };


        this.gun.get('chat').map(match).once(async (data) => {
        //this.user.get('public_posts').once(async (data) => { 
            if (data) {
                //console.log(data);
                //console.log("Users publickey: " + await this.gun.user(data).get('pub'));

                let post = {
                    post_username : await this.gun.user(data).get('alias'),
                    user_ref : await this.gun.user(data),
                    message : await SEA.verify(data.message, await this.gun.user(data).get('pub')),
                    time_stamp : Gun.state.is(data, 'message'),
                    post_pubKey : await this.gun.user(data).get('epub'),
                    soul : data.soul,
                }

                this.setState({ post_feed : [...this.state.post_feed, post].sort( (a, b) => b.time_stamp - a.time_stamp)});
            }
        });
    }

    onNewClick(e) {
        this.props.navigator.pushPage({comp: NewPost, props: {
            key: 'newpost',
            gun : this.gun,
            user : this.user,
            profile : this.props.profile,
            navigator : this.props.navigator,
        }});
    }

    getAutoResponsiveProps() {
        return {
            itemMargin: 0,
            containerWidth: this.state.containerWidth || document.body.clientWidth,
            itemClassName: 'item',
            gridWidth: 100,
            transitionDuration: '.5'
        };
    }

    render() {
        return(
            <Page renderToolbar={ err => {
                return(
                    <Toolbar>
                        <div className="center">Home - Welcome {this.props.profile.username} </div>
                        {this.props.profile && (
                            <div className="right"><LogoutButton gun={this.gun} user={this.user} profile={this.props.profile} logoutHandler={this.props.logoutHandler} /></div>
                        )}
                    </Toolbar>
                )}} >
                <Fab onClick={this.onNewClick} position="right bottom" ripple>
                    <Icon icon="md-plus" />
                </Fab>     
                <WaterfallGrid children={this.state.post_feed.map( (post, i ) => {
                    return(
                        <div key={i} style={{ width : 320 }}>
                            <Post
                                post={post}
                                gun={this.gun}
                                user={this.user}
                                profile={this.props.profile}
                                navigator={this.props.navigator}
                                />
                        </div>)
                })} 
                childWidth={320}
                styleGridContainer={{ width: "100%", position: "relative", justifyContent: "center", zIndex: 1 }}/>           
            </Page>
        );
    }

}

export default Home;