import React from 'react';
import ReactDOM from 'react-dom';


import { Scroller, Splitter, SplitterSide, SplitterContent, Card, Page, Toolbar, Button, Fab, Icon, BackButton, Row, Col, LazyList, List, ListTitle, ListItem } from 'react-onsenui';
import { Input } from 'react-onsenui';
import {WaterfallGrid} from 'react-waterfall-grid';
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'

import Gun from 'gun/gun';
import SEA from 'gun/sea';

import LogoutButton from './LogoutButton';
import NewChannel from './NewChannel';
import ChatBox from './ChatBox';

require('gun/lib/then.js');

class Chat extends React.Component {
    constructor(props) {
        super(props);

        this.onOpen = this.onOpen.bind(this);
        this.onClose = this.onClose.bind(this);
        this.handleResize = this.handleResize.bind(this);

        this.onNewChannel = this.onNewChannel.bind(this);
        this.displayFriendsChats = this.displayFriendsChats.bind(this);
        this.displayChatBox = this.displayChatBox.bind(this);

        this.user = props.user;
        this.gun = props.gun;

        this.state = {
            isOpen : false,
            windowHeight : 0,

            displayFriendsList : false,

            chat_open : false,
            activeChatFriend : null,

        }

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    handleResize(e) {
        this.setState({ windowHeight : window.innerHeight});
    }

    onOpen(e) {this.setState({isOpen: true});}
    onClose(e) {this.setState({isOpen: false});}


    onNewChannel(e) {
        this.props.navigator.pushPage({comp: NewChannel, props: {
            key: 'newpost',
            gun : this.gun,
            user : this.user,
            profile : this.props.profile,
            navigator : this.props.navigator,
        }});
    }

    displayFriendsChats(e) {
        //console.log("Friends Clicked");
        this.setState({ displayFriendsList : true });
    }

    async displayChatBox(e, key, user_ref) {
        console.log("Display Chat Window Chlick")
        console.log(e);
        console.log(user_ref);
        this.setState({ active_user_ref : user_ref});
        this.setState({ active_friend_key : key });
        this.setState({ chat_open : true });
        this.onClose();

    }

    render() {
        return(
            <Page renderToolbar={ err => {
                return(
                    <Toolbar>
                        <div className="left">
                            {!this.state.isOpen && (
                                <Button onClick={this.onOpen}><Icon icon="fa-angle-double-right"/></Button>
                            )}
                            {this.state.isOpen && (
                                <Button onClick={this.onClose}><Icon icon="fa-angle-double-left"/></Button>
                            )}
                        </div>
                        <div className="center">Chat</div>
                        {this.props.profile && (
                            <div className="right"><LogoutButton gun={this.gun} user={this.user} profile={this.props.profile} logoutHandler={this.props.logoutHandler} /></div>
                        )}
                    </Toolbar>
                )}} > 
                <Splitter>
                    <SplitterSide
                        side="left"
                        width={320}
                        swipeable={true}
                        collapse={true}
                        isOpen={this.state.isOpen}
                        onClose={this.onClose}
                        onOpen={this.onOpen}>
                    <Page renderToolbar={ err => {return(<Toolbar><div className="center">Chat</div>
                                                <div className="right"><Button onClick={this.onNewChannel}><Icon icon="plus" /></Button></div>
                                            </Toolbar>)}}>
                        <Row>
                            <Col width="50%">
                                <List>
                                    <ListItem onClick={this.displayFriendsChats} tappable><Icon icon="fa-user-friends" /> Friends</ListItem>
                                        <List>
                                            <ListTitle>Channels</ListTitle>
                                            <ListItem>Test</ListItem>
                                        </List>
                                </List>
                            </Col>
                            <Col width="50%">
                            {this.state.displayFriendsList && (
                                <List>
                                    {this.props.profile.friends_list.map( (data, i) => {
                                        console.log("Friend chat found");
                                        console.log(data);
                                        return (
                                            <ListItem key={data.pubKey} onClick={e => this.displayChatBox(e, data.pubKey, data.user_ref)} tappable>{data.username}</ListItem>
                                        )
                                    })}
                                </List>
                            )}
                            </Col>
                        </Row>
                    </Page>
                    </SplitterSide>
                    <SplitterContent>
                    <Page> 
                        { this.state.chat_open && (<ChatBox friend_ref={this.state.active_user_ref} friend_key={this.state.active_friend_key} gun={this.gun} user={this.user} />)} </Page>
                    </SplitterContent>
                </Splitter>
            </Page>
        );
    }

}

export default Chat;