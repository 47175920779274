import React from 'react';

import ons from 'onsenui';
import { Tabbar, List, Tab, Icon, Card, Page, Toolbar, Button, Navigator, Template, Splitter, Col, Row, ListItem } from 'react-onsenui';
import { Input } from 'react-onsenui';

import 'onsenui/css/onsenui.css';
//import 'onsenui/css/onsen-css-components.css';
//import 'onsenui/css/dark-theme.css';
import 'onsenui/css/dark-onsen-css-components.css';

//import 'onsenui/css/font_awesome/css/font-awesome.min.css';
//import 'onsenui/css/ionicons/css/ionicons.min.css';
//import 'onsenui/css/material-design-iconic-font/css/material-design-iconic-font.min.css';

import Gun from 'gun/gun'
import SEA from 'gun/sea'


import Home from './components/Home';
import Chat from './components/Chat';
import Search from './components/Search';
import Profile from './components/Profile';

require('gun/lib/then.js');

class Tabs extends React.Component {
  renderTabs() {
    return [
      {
        content: <Home key="home"
                    navigator={this.props.navigator}
                    app_context={this.props.app_context}
                    profile={this.props.profile}
                    gun={this.props.gun}
                    user={this.props.user}
                    profile={this.props.profile}
                    logoutHandler={this.props.logoutHandler} />,
        tab: <Tab key="home" label="Home" icon="fa-rocket" />
      },
      {
        content: <Chat key="chat"
                    navigator={this.props.navigator}
                    app_context={this.props.app_context}
                    profile={this.props.profile}
                    gun={this.props.gun}
                    user={this.props.user}
                    profile={this.props.profile} 
                    logoutHandler={this.props.logoutHandler}/>,
        tab: <Tab key="chat" label="Chat" icon="fa-satellite-dish" />
      },
      {
        content: <Search key="search"
                    navigator={this.props.navigator}
                    app_context={this.props.app_context}
                    profile={this.props.profile}
                    gun={this.props.gun}
                    user={this.props.user}
                    profile={this.props.profile} 
                    logoutHandler={this.props.logoutHandler}/>,
        tab: <Tab key="search" label="Search" icon="fa-satellite" />
      },
      {
        content: <Profile key="profile"
                    navigator={this.props.navigator}
                    app_context={this.props.app_context}
                    profile={this.props.profile}
                    gun={this.props.gun}
                    user={this.props.user}
                    profile={this.props.profile} 
                    logoutHandler={this.props.logoutHandler}/>,
        tab: <Tab key="profile" label="profile" icon="fa-user-astronaut" />
      },
    ];
  }

  render() {
    return (
      <Page>
        <Tabbar
          position="bottom"
          renderTabs={this.renderTabs.bind(this)}
        />
      </Page>
    );
  }
}

class App extends React.Component {
  constructor(props){
    super(props);
    //this.gun = Gun('https://192.168.178.29:3030/gun');
    this.gun = Gun('https://alternis-relay.herokuapp.com/gun');
    //this.gun = Gun();
    window.gun = this.gun;
    this.user = null;
    this.app_context = this.gun.get('application_key');

    //Auth Handler Methods
    this.updateUsername = this.updateUsername.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.onLoginSubmit = this.onLoginSubmit.bind(this);
    this.onRegisterSubmit = this.onRegisterSubmit.bind(this);
    this.logoutCallback = this.logoutCallback.bind(this);

    this.state = {
      profile : null,

      username : "",
      password: "",
    }
  }

  componentDidMount() {
    console.log("App component mounted.");
    //Setup the event monitor for auth events
    this.gun.on('auth', async (ack) => {
      
        console.log("Auth Event");
        console.log(ack);

        let profile = {
        soul : ack.soul,
        pubKey : ack.sea.pub,
        username : await await this.gun.user(ack.pubKey).get('alias'),
        alias : await await this.gun.user(ack.pubKey).get('alias'),
        friends_list : [],
      }
      this.setState({ profile : profile });

      this.gun.user(ack.pubKey).get('friends_list').map().on( async (data, i) => {
        console.log('friend located');
        console.log(data);
        console.log(i);
        console.log(data.pubKey);
        let tmp = {
            key : i,
            pubKey : data.pubKey,
            username : data.username,
            user_ref : data.user_ref,
        }
        console.log(tmp);
        let profile = this.state.profile;
        profile.friends_list.push(tmp);
        this.setState({ profile : profile });
      });

    });



  this.user = this.gun.user().recall({sessionStorage: true}, (ack) => {console.log(ack)});
    if (!this.user.is) {
      this.user.leave();
    }

  }
  

  updateUsername(e) {
    //console.log("Username update:" + e.target.value);
    //console.log(e.target.value);
    this.setState({ username : e.target.value });
  }

  updatePassword(e) {
    //console.log("Password update:" + e.target.value);
    //console.log(e.target.value);
    this.setState({ password : e.target.value });
  }

  onRegisterSubmit(e) {
      //console.log("Creating a new user: ");
      //console.log(this.state.username);
      //console.log(this.state.password);
      this.user.create(this.state.username, this.state.password, (ack) => {
        this.user.auth(this.state.username, this.state.password);
      });
  }

  onLoginSubmit(e) {
      //console.log("Attempting to log on user: ");
      //console.log(this.state.username);
      //console.log(this.state.password);
      this.user.auth(this.state.username, this.state.password);
  }


  renderPage(route, navigator) {
    route.props = route.props || {};
    route.props.navigator = navigator;
    return React.createElement(route.comp, route.props);
  }

  logoutCallback(e) {
    //console.log("User logout");
    this.user.leave();
    this.setState({ profile : null });
  }

  render() {
    return(
      <Page style={{textAlign: 'center'}}>
        {!this.state.profile && (
                  <Navigator
                    initialRoute={{ 
                      comp: () => { return(<Page>
                            <Tabbar
                              position="top"
                              renderTabs={ (activeIndex, tabbar) =>
                                [
                                  {
                                    content: <Page>          
                                                <Card className="center" style={{ width : 'fit-content', display: 'inline-block'}}>
                                                  <img src="./logo192.png" />
                                                  <div className="title">
                                                    Welcome to Alternis.Space!
                                                  </div>
                                                  <div className="content">
                                                    <List>
                                                      <ListItem><Input modifier="underbar" placeholder="Username" float onInput={this.updateUsername} /></ListItem>
                                                      <ListItem><Input modifier="underbar" placeholder="Password" float type="password" onInput={this.updatePassword} /></ListItem>
                                                      <ListItem><Button onClick={this.onLoginSubmit}><Icon icon="fa-sign-in-alt"/></Button></ListItem>
                                                    </List>
                                                  </div>
                                                </Card>
                                              </Page>,
                                    tab: <Tab key="login" label="Login" icon="fa-rocket" />
                                  },
                                  {
                                    content: <Page>          
                                              <Card className="center" style={{ width : 'fit-content', display: 'inline-block'}}>
                                                <img src="./logo192.png" />
                                                <div className="title">
                                                  Create a new user!
                                                </div>
                                                <div className="content">
                                                  <List>
                                                    <ListItem><Input modifier="underbar" placeholder="Username" float onInput={this.updateUsername} /></ListItem>
                                                    <ListItem><Input modifier="underbar" placeholder="Password" float type="password" onInput={this.updatePassword} /></ListItem>
                                                    <ListItem><Button onClick={this.onRegisterSubmit}><Icon icon="fa-plus-circle" /></Button></ListItem>
                                                  </List>
                                                </div>
                                              </Card>
                                            </Page>,
                                    tab: <Tab key="register" label="Register" icon="fa-satellite-dish" />
                                  },
                                ]
                              }
                            />
                          </Page>)},
                    props: { 
                      key: 'logintabs',
                      app_context : this.app_context,
                      profile : this.state.profile,
                      gun : this.gun,
                      user : this.user,
                    }
                  }}
                  renderPage={this.renderPage}
                />
        )}
        {this.state.profile && (
                  <Navigator
                  initialRoute={{
                    comp: Tabs,
                    props: { 
                      key: 'tabs',
                      app_context : this.app_context,
                      profile : this.state.profile,
                      gun : this.gun,
                      user : this.user,
                      logoutHandler : this.logoutCallback,
                    }
                  }}
                  renderPage={this.renderPage}
                />
        )}
      </Page>
      
    );
  }

/*
  renderdasdsad() {
    
    
    initialRoute={{
          comp:  <Page>
                  <Tabbar
                    renderTabs={this.renderTabs.bind(this)}
                  />
                  </Page>,
          props: { key: 'tabs' }
        }}
        renderPage={(route, navigator) => {
          route.props = route.props || {};
          route.props.navigator = navigator;
          return React.createElement(route.comp, route.props);
        }}
        />
    );
  }
    return(
      <div>
         <Page id="root_page">
          {this.state.profile && (
              <Card className="right" style={{marginTop : 50}}>
                <p>{"Welcome " + this.state.username}</p>
                <Button onClick={this.onLogoutSubmit}>Log Out</Button>
              </Card>
            )}
            {!this.state.profile && (
              <Card className="center">
                  <Input modifier="underbar" placeholder="Username" float onInput={this.updateUsername} />
                  <Input modifier="underbar" placeholder="Password" float type="password" onInput={this.updatePassword} />
                  <Button onClick={this.onLoginSubmit}>Login</Button>
                  <Button onClick={this.onRegisterSubmit}>Register</Button>
              </Card>
            )}
            {this.state.profile && (
              <Card className="center" style={{ marginTop : 10}}>
                <ChatBox app_context={this.app_context} profile={this.state.profile} gun={this.gun} user={this.user} profile={this.state.profile} />
              </Card>
            )}
        </Page>
      </div>    
    );
  }*/

}

export default App
