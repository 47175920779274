import React from 'react';
import ReactDOM from 'react-dom';


import { Scroller, Splitter, SplitterSide, SplitterContent, Card, Page, Toolbar, Button, Fab, Icon, BackButton, Row, Col, LazyList, List, ListTitle, ListItem } from 'react-onsenui';
import { Input } from 'react-onsenui';
import {WaterfallGrid} from 'react-waterfall-grid';
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'

import Gun from 'gun/gun';
import SEA from 'gun/sea';

require('gun/lib/then.js');

class UserGraph extends React.Component {

    constructor(props) {
        super(props);

        this.gun = props.gun;
        this.user = props.gun;

        this.state = {
            user_graph_data : null,
        }

    }

    componentDidMount() {
        this.loadUserGraph();
    }

    loadUserGraph() {
        console.log("Getting user graph");
        this.setState({ user_graph_data : this.gun.user(this.props.profile.pubKey) })
    }

    render() {
        return(
            <div style={{ textAlight : 'center' }}>
                <List style={{ display: 'inline-block' }}>
                    <h3>User Graph</h3>
                    <ListItem key="profile">
                        <h4>User Profile State</h4>
                        <List>
                        <ListItem>Soul: {this.props.profile.soul}</ListItem>
                        <ListItem>pubKey:  {this.props.profile.pubKey}</ListItem>
                        <ListItem>username:  {this.props.profile.username}</ListItem>
                        <ListItem>alias:  {this.props.profile.alias}</ListItem>
                        {this.props.profile.friends_list.map( (data, key) => {
                            console.log(data);
                            console.log(key);
                        })}
                        </List>    
                    </ListItem>
                    <ListItem key="graph">
                        <h4>Users Gun Graph</h4>
                    </ListItem>
                </List>
            </div>
        );
    }

}

export default UserGraph;