import React from 'react';

import { Card, Page, Toolbar, Button, Fab, Icon, BackButton } from 'react-onsenui';
import { Input } from 'react-onsenui';
import MDEditor from '@uiw/react-md-editor';

import Gun from 'gun/gun';
import SEA from 'gun/sea';

import LogoutButton from './LogoutButton';

require('gun/lib/then.js');

class NewPost extends React.Component {
    constructor(props) {
        super(props);
        console.log(props);
        this.updateText = this.updateText.bind(this);
        this.onSay = this.onSay.bind(this);
        this.user = props.user;
        this.gun = props.gun;
        this.navigator = props.navigator;

        this.state = {
            text : "",
        }
    }
    
    renderToolbar() {
      return (
        <Toolbar>
            <div className='left'>
                <BackButton></BackButton>
            </div>
            <div className="center">New Post</div>
        </Toolbar>
      );
    }

    updateText(e) {
      console.log(e);
        this.setState({ text : e });
    }

    async onSay(e) {
      if (this.user.is) {
        //console.log("Posting message.");
        //console.log(this.user);
        //let encrypted_message = await SEA.encrypt(this.state.text, this.user._.sea.epub);
        let signed_message = await SEA.sign(this.state.text, this.user._.sea);
        //console.log(await SEA.decrypt(encrypted_message, this.user._.sea.epub));
        let payload = await this.user.get('public_posts').set( { message : signed_message, time_stamp : new Date().toISOString() });
        //let payload = this.user.get('all').set( { message : encrypted_message });
        let index = new Date().toISOString();
        //console.log(payload);
        this.gun.get('chat').get(index).put(payload);
        this.props.navigator.popPage();
      }

    }   
  
    render() {
      return (
        <Page renderToolbar={this.renderToolbar}>
          <div style={{textAlign: 'center'}}>
            <br />
            <form className="post_form" onSubmit={this.onSay}>
              <MDEditor
                value={this.state.text}
                onChange={this.updateText}
                preview={'edit'}
                hideToolbar={'true'}
                textareaProps={{
                  placeholder : "Say something! GitHub flavoured markdown supported!",
                }}
                style={{  }}
                />
              <p>{"Say Something"}</p>
              <Button type="submit" onClick={this.onSay}>Say</Button>
            </form>
          </div>
        </Page>
      );
    }
  }

export default NewPost