import React from 'react';
import ReactDOM from 'react-dom';


import { Scroller, Splitter, SplitterSide, SplitterContent, Card, Page, Toolbar, Button, Fab, Icon, BackButton, Row, Col, LazyList, List, ListTitle, ListItem } from 'react-onsenui';
import { Input } from 'react-onsenui';
import {WaterfallGrid} from 'react-waterfall-grid';
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'

import Gun from 'gun/gun';
import SEA from 'gun/sea';

class FriendsList extends React.Component {

    constructor(props) {
        super(props);

        this.onRemoveFriend = this.onRemoveFriend.bind(this);

        this.state = {
            friends : [],
        }

    }


    onRemoveFriend(e, data) {
        console.log(e);
        console.log(data);

        let new_array = this.props.profile.friends_list.filter( el => el.key !== data.key );
        this.props.profile.friends_list = new_array;

        

    }


    render() {
        return(
            <div style={{ textAlight : 'center' }}>
                <h3>FRIENDS LIST</h3>
                <List style={{ width : "500", display: 'inline-block' }}>
                {this.props.profile.friends_list.map( (data) => {
                    return( 
                        <ListItem key={data.key}>
                            <Row>
                                <Col>
                                    <img
                                        src={'https://avatars.dicebear.com/api/initials/' + data.username + '.svg'}
                                        alt="avatar"
                                        style={{
                                            width : 40,
                                            height : 40,
                                            margin : 2,
                                            borderRadius : '50%',
                                        }}/>
                                </Col>
                                <Col>
                                    {data.username}
                                </Col>
                                <Col>
                                    <Button onClick={e => this.onRemoveFriend(e, data)}><Icon icon="fa-user-slash" /></Button>
                                </Col>
                            </Row>
                        </ListItem>
                    );
                })}
                </List>
            </div>
        );
    }

}

export default FriendsList;