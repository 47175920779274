import React from 'react';
import ReactDOM from 'react-dom';


import { Card, Page, Toolbar, Button, Fab, Icon, BackButton, Row, Col, LazyList, List, ListItem } from 'react-onsenui';
import { Input } from 'react-onsenui';
import {WaterfallGrid} from 'react-waterfall-grid';
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'

import Gun from 'gun/gun';
import SEA from 'gun/sea';

import LogoutButton from './LogoutButton';

require('gun/lib/then.js');

class Search extends React.Component {
    constructor(props) {
        super(props);
        this.user = props.user;
        this.gun = props.gun;


        this.state = {
        }

    }

    componentDidMount() {
 
    }

  

    render() {
        return(
            <Page renderToolbar={ err => {
                return(
                    <Toolbar>
                        <div className="center">Search</div>
                        {this.props.profile && (
                            <div className="right"><LogoutButton gun={this.gun} user={this.user} profile={this.state.profile} logoutHandler={this.props.logoutHandler} /></div>
                        )}
                    </Toolbar>
                )}} >      
            </Page>
        );
    }

}

export default Search;